import React, { useState, useEffect } from 'react';
import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, {
  ButtonGroup,
  PricingArea,
  InnerWrapper,
  PricingCard,
} from '../../pricingPolicy.style';

// TO DO: replace with your own Public API Key
builder.init('ac26b036544e43e285ccd772fe89c64b');

function BuilderPageComponent({ match }) {
  const [homepage, setHomepage] = useState(null);
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    builder
      .get('hotels-about-page', {
        userAttributes: {
          // To allow targeting different editorials at different pages (URLs) and products
          urlPath: window.location.pathname,
          /*  productId: product.id,
          collection: product.collection */
        },
      })
      .toPromise()
      .then((homepage) => setHomepage(homepage));
  }, []);

  // if no page is found, return a 404 page
  if (notFound && !isPreviewingInBuilder) {
    return <div>Not Found 404 Error</div>;
  }

  return (
    <>
      <SectionWrapper id="pricing">
        <Container className="containerClass">
          <InnerWrapper>
            <BuilderComponent
              model="hotels-about-page"
              //content="/" /* content={homepage} */
            />
          </InnerWrapper>
        </Container>
      </SectionWrapper>
    </>
  );
}

export default BuilderPageComponent;
